<template>
  <!-- <client-only> -->
  <div class="h-auto lg:h-[610px] relative isolate">
    <div
      v-if="data?.slides.length > 1"
      class="container absolute inset-0 justify-end items-end z-[99] hidden lg:flex"
    >
      <div
        class="ml-auto mb-37.5 shrink-0 max-lg:hidden flex items-center gap-7"
      >
        <div class="flex text-white items-center gap-4.5 ui-text-m font-medium">
          <div>
            {{ currentSlide }}
          </div>
          <div class="h-px w-[135px] bg-transparent">
            <div
              class="h-px bg-white transition-all"
              :style="{ width: `${progress}%` }"
            />
          </div>
          <div>
            {{ nextSlide }}
          </div>
        </div>
        <div>
          <button
            ref="prevEl"
            :aria-label="$t('previousSlide')"
            class="mr-5 inline-flex h-[46px] w-[46px] items-center justify-center rounded-full border border-solid text-white border-white transition-all disabled:opacity-50"
          >
            <UiIcon name="arrow_left" class="size-6" />
          </button>
          <button
            ref="nextEl"
            :aria-label="$t('nextSlide')"
            class="inline-flex h-[46px] w-[46px] items-center justify-center rounded-full border border-solid text-white border-white transition-all disabled:opacity-50"
          >
            <UiIcon name="arrow_right" class="size-6" />
          </button>
        </div>
      </div>
    </div>
    <Swiper
      :autoplay="sliderAutoplay"
      :modules="modules"
      :navigation="navigation"
      aria-label="Home Slider Images"
      :slides-per-view="1"
      :space-between="5"
      class="h-auto lg:h-[610px]"
      :class="pageClass"
      @slideChange="onSlideChange"
      @autoplayTimeLeft="autoplayTimeLeft"
    >
      <SwiperSlide v-for="item in data?.slides" :key="item.id">
        <div
          class="flex h-full flex-col"
          :class="{ 'flex-col-reverse': data.bottomMobile }"
          @click="onClick(item?.buttonUrl)"
        >
          <div class="cursor-pointer h-full max-lg:max-h-[410px]">
            <UiLazyImg
              class="max-lg:h-[482px] h-full object-center"
              :src="item?.file?.url || ''"
              :src-mobile="item?.fileMobile?.url || ''"
              :alt="
                item?.file?.alt ||
                item.title ||
                removeHTMLTagFromString(item.text) ||
                'Image'
              "
              cover
              :no-lazy="!blockIndex"
            />
          </div>
          <div
            class="w-full h-fit"
            :style="[
              lgAndDown
                ? {
                    backgroundColor:
                      item?.backgroundColorMobile?.hexa ||
                      item?.backgroundColor?.hexa ||
                      '#F4EEE8',
                  }
                : {},
            ]"
          >
            <div
              class="container inset-0 flex h-full items-center justify-center py-10 lg:absolute lg:justify-end lg:py-4"
            >
              <div class="w-full" @click="contentLink" v-html="item.text" />
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
  <!-- </client-only> -->
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import { NavigationOptions } from "swiper/types";
import { Pagination, Autoplay, Navigation } from "swiper";
import { BlockSliderContent } from "ecom-types";
import "swiper/css/pagination";
import { useAppBreakpoints } from "~/composables";

const route = useRoute();
const router = useRouter();
const localePath = useLocalePathPolyfill();
const contentLink = useContentLink;
const { lgAndDown } = useAppBreakpoints();
const props = withDefaults(
  defineProps<{
    data: BlockSliderContent;
    blockIndex?: number;
  }>(),
  {
    blockIndex: -1,
  },
);

const currentSlide = ref("1");
const nextSlide = ref("2");
const progress = ref(0);
const prevEl = ref<HTMLElement | null>(null);
const nextEl = ref<HTMLElement | null>(null);

const navigation = reactive({
  prevEl,
  nextEl,
  disabledClass: "opacity-20 cursor-not-allowed hover:bg-transparent",
}) as NavigationOptions;

const modules = [Pagination, Autoplay, Navigation];

const slidesCount = computed(() => props.data?.slides?.length || 0);

const pageClass = computed(() => {
  const slug = route.path.slice(1)?.split("/")?.join("-") || "home";
  return slug + "-" + props.blockIndex;
});

const sliderAutoplay = {
  delay: 5000,
  disableOnInteraction: false,
};

function onClick(url?: string) {
  if (url) {
    router.push(localePath(url));
  }
}

function onSlideChange(swiper) {
  const currentIndex = swiper.realIndex + 1;
  const nextIndex = (currentIndex % slidesCount.value) + 1;

  currentSlide.value = currentIndex.toString().padStart(2, "0");
  nextSlide.value = nextIndex.toString().padStart(2, "0");
}

function autoplayTimeLeft(_, _time, progressValue) {
  progress.value = Math.floor((1 - progressValue) * 100);
}
</script>
